import freeEmailDomains from 'free-email-domains';
import { useHistory } from 'react-router-dom';
import { ProperPathName } from './routes';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

export const countryMapper = {
	'United States': 'US',
	Pakistan: 'PK',
};

export const setCookie = function (cname, cvalue, exdays) {
	const d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);

	const expires = 'expires=' + d.toUTCString();
	document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

export const utcToDate = (utc, month = 'long') => {
	const timestamp = utc * 1000; // Convert seconds to milliseconds
	const date = new Date(timestamp);
	const options = { year: 'numeric', month: month, day: 'numeric' };
	const formattedDate = date.toLocaleDateString('en-US', options);
	return formattedDate; // Output the formatted date

};

export const getCookie = function (cname) {
	const ca = document.cookie.split(';');

	const token = ca.find((c) => {
		return c.replace(' ', '').split('=')[0] === cname;
	});

	if (!token) {
		return '';
	}

	return token.split('=')[1];
};
export const deleteCookie = function (cname) {
	// const d = new Date();
	// d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);

	const expires = 'expires=' + 'Thu, 01 Jan 1970 00:00:00 GMT';
	document.cookie = `${cname}=${''};${expires};path=/`;
};
/**
 *
 * @param {string} email Email to be validated
 * @returns {boolean} true if email is valid else false
 */
export const isEmailValid = function (email) {
	const validationRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g;
	return validationRegex.test(email);
};

export const isEmailPublic = function (email) {
	const domain = email?.split('@')[1];

	return freeEmailDomains.includes(domain);
};

/**
 *
 * @param {string} password Password to be validated
 */
export const isPasswordValid = function (password) {
	const validationRegex =
		/^(?=.*[~!@#$%^&*()_+|\-=\\[\]{};':"\\|,.<>\\/?0-9])(?=.*[a-zA-Z]).{10,}$/g;
	return validationRegex.test(password);
};

export const toTitleCase = function (str) {
	return str?.replace('_', ' ').replace(/(?:^|\s)\w/g, function (match) {
		return match.toUpperCase();
	});
};

/**
 *
 * @param {Date} date
 */
export const getFormatedDate = (date, onlyDate = false, onlyTime = false) => {
	if (typeof date === 'string') {
		date = new Date(date);
	}

	return date?.toLocaleString('default', {
		...(!onlyTime && { year: 'numeric', month: 'short', day: 'numeric' }),
		...(!onlyDate && {
			hour: 'numeric',
			hour12: true,
			minute: 'numeric',
			second: 'numeric',
		}),
	});
};

export const removeAllCookies = function () {
	var cookies = document.cookie.split(';');
	cookies.map((cookie) => {
		if (!cookie.includes('UserCookieConsent')) {
			deleteCookie(cookie.split('=')[0]);
		}
	});
	window.location.reload();
	// for (var i = 0; i < cookies.length; i++)

};

export const secondsToDhms = (seconds, round_of_seconds = false) => {
	seconds = Number(seconds);
	var d = Math.floor(seconds / (3600 * 24));
	var h = Math.floor(seconds % (3600 * 24) / 3600);
	var m = Math.floor(seconds % 3600 / 60);
	var s = Math.floor(seconds % 60);

	if (round_of_seconds) {
		s = 0;
	}

	// var dDisplay = d > 0 ? d + (d == 1 ? ' day ' : ' days ') : ':';
	// var hDisplay = h > 0 ? h + (h == 1 ? ' hour ' : ' hours ') : ':';
	// var mDisplay = m > 0 ? m + (m == 1 ? ' minute ' : ' minutes ') : ':';
	// var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';

	var dDisplay = d > 0 ? (d < 10 ? `0${d}:` : `${d}:`) : '';
	var hDisplay = h > 0 ? (h < 10 ? `0${h}:` : `${h}:`) : '';
	var mDisplay = m > 9 ? `${m}:` : `0${m}:`;
	var sDisplay = s > 9 ? `${s}` : `0${s}`;

	return dDisplay + hDisplay + mDisplay + sDisplay;
};

export const secondsToDhmsFormat = (seconds, showCompleteTime = false, round_of_seconds = false) => {
	seconds = Number(seconds);
	var d = Math.floor(seconds / (3600 * 24));
	var h = Math.floor(seconds % (3600 * 24) / 3600);
	var m = Math.floor(seconds % 3600 / 60);
	var s = Math.floor(seconds % 60);

	if (round_of_seconds) {
		m = s >= 30 ? (m + 1) : (m);
		s = 0;
	}

	var dDisplay = d > 0 ? (d === 1 ? `${d} day ` : `${d} days `) : '';
	var hDisplay = h > 0 ? (h === 1 ? `${h} hr ` : `${h} hrs `) : '';
	var mDisplay = m > 0 ? (m === 1 ? `${m} min ` : `${m} mins `) : '';
	var sDisplay = s > 0 ? (s === 1 ? `${s} sec` : `${s} sec`) : '';

	if (showCompleteTime) {
		return dDisplay + hDisplay + mDisplay + sDisplay;
	}

	if (d > 0) {
		return dDisplay + hDisplay;
	} else if (h > 0) {
		return hDisplay + mDisplay;
	} else if (m > 0) {
		return mDisplay + sDisplay;
	} else {
		return sDisplay;
	}
};


export const secondsTohhmm = (seconds) => {
	seconds = Number(seconds);
	var h = Math.floor(seconds % (3600 * 24) / 3600);
	var m = Math.floor(seconds % 3600 / 60);
	var s = Math.floor(seconds % 60);
	let mDisplay = '';
	let hDisplay = '';


	if (h) {
		hDisplay = h > 9 ? `${h} ${h == 1 ? 'hr' : 'hrs'}` : `${h} ${h == 1 ? 'hr' : 'hrs'}`;
	}
	mDisplay = m > 9 ? `${h ? ' ' : ''}${m} ${m == 1 ? 'min' : 'mins'}` : `${h ? ' ' : ''}0${m} ${m == 1 ? 'min' : 'mins'}`;
	return hDisplay + mDisplay;
};

export const calculateTestResultPrevScore = (test_Score, testType) => {
	let totalScore = 0;
	if (testType === 'coding_test') {
		totalScore += test_Score?.score ? test_Score?.score * 10 : 0;
	} else {
		totalScore += parseFloat(
			(10 / test_Score?.total_questions) * test_Score?.correct_answers
		);
	}
	return totalScore.toFixed(1) || 0;
};

export const calculateScore = (_test) => {
	let totalScore = 0;
	if (_test[0]?.test_type === 'coding_test') {
		_test.map((_eachTest, _) => {
			totalScore += _eachTest?.score ? _eachTest?.score * 10 : _eachTest?.prev_test_results?.score_object?.score ? _eachTest?.prev_test_results?.score_object?.score * 10 : 0;
		});
	} else {
		_test.map((_eachTest, _) => {
			const totalQuestions = _eachTest?.total_questions ? _eachTest?.total_questions : _eachTest?.prev_test_results ? _eachTest?.prev_test_results?.score_object?.total_questions : 0;
			const correctAnswers = _eachTest?.correct_answers ? _eachTest?.correct_answers : _eachTest?.prev_test_results ? _eachTest?.prev_test_results?.score_object?.correct_answers : 0;
			totalScore += parseFloat((10 / totalQuestions) * correctAnswers);
		});
	}
	return totalScore / _test.length || 0;
};

export const calculateExpiry = (deadline, createdAt, defaultFormat = false) => {
	const createdAtDate = new Date(createdAt);
	const newDate = new Date(createdAtDate.getTime() + deadline * 1000); // Convert seconds to milliseconds

	// Format the date as "Month Day, Year / Hour:Minute AM/PM"
	const formattedDate = newDate.toLocaleString('en-US', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		hour: 'numeric',
		minute: '2-digit',
		hour12: true,
		second: 'numeric',
	});

	return formattedDate.replace('at', '/');
};

export const calculateTimeDifference = (expiryDate) => {
	const providedDate = new Date(expiryDate);

	// Get the current date
	const currentDate = new Date();

	// Calculate the time difference in minutes
	const timeDifferenceInMinutes = (providedDate - currentDate) / (1000 * 60);

	return timeDifferenceInMinutes;
};

export const calculateTimeDifferenceInDays = (expiryDate) => {
	const targetDate = new Date(formatDateFromSeconds(expiryDate));
	const currentDate = new Date();

	const differenceInDays = (targetDate - currentDate) / (1000 * 3600 * 24);
	const daysLeft = Math.ceil(differenceInDays);

	return daysLeft;
};

export const timeDifference = (date) => {
	const timeInMinutes = (new Date() - new Date(date)) / (1000 * 60);
	const timeInHours = timeInMinutes / 60;
	const timeInDays = timeInMinutes / 1440;
	const timeInWeeks = timeInMinutes / 10080;
	const timeInMonths = timeInDays / 30;

	if (timeInMinutes < 1) {
		return 'Just now';
	} else if (timeInMinutes < 60) {
		return `${Math.floor(timeInMinutes)} minute${Math.floor(timeInMinutes) === 1 ? '' : 's'} ago`;
	} else if (timeInHours < 24) {
		return `${Math.floor(timeInHours)} hour${Math.floor(timeInHours) === 1 ? '' : 's'} ago`;
	} else if (timeInDays < 7) {
		if (Math.floor(timeInDays) === 1) {
			return 'Yesterday';
		} else {
			return `${Math.floor(timeInDays)} days ago`;
		}
	} else if (timeInDays < 30) {
		return `${Math.floor(timeInWeeks)} week${Math.floor(timeInWeeks) === 1 ? '' : 's'} ago`;
	} else if (timeInDays < 365) {
		if (Math.floor(timeInMonths) === 1) {
			return 'Last month';
		} else {
			return `${Math.floor(timeInMonths)} month${Math.floor(timeInMonths) === 1 ? '' : 's'} ago`;
		}
	} else if (timeInDays >= 365 && timeInDays < 730) {
		return 'Last year';
	} else {
		const years = Math.floor(timeInDays / 365);
		return `${years} year${years === 1 ? '' : 's'} ago`;
	}
};
export const formatDate = (inputTime) => {
	const months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
		'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
	];

	const date = new Date(inputTime);
	const month = months[date.getMonth()];
	const day = date.getDate();
	const year = date.getFullYear();
	let hours = date.getHours();
	const minutes = date.getMinutes();
	const ampm = hours >= 12 ? 'PM' : 'AM';

	hours = hours % 12;
	hours = hours ? hours : 12;

	const formattedTime = `${month} ${day}, ${year}, ${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;

	return formattedTime;
};


export const dateDiffInDaysAndHours = (expiryDate) => {
	const targetDate = new Date(formatDateFromSeconds(expiryDate));
	const startDate = new Date();
	const timeDifference = targetDate - startDate;

	const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
	const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

	if (days > 0) {
		return `${days} day${days > 1 ? 's' : ''}`;
	} else {
		return `${hours} hour${hours !== 1 ? 's' : ''}`;
	}
};

export const isoDateFormateFromSeconds = (dateString) => {
	const date = new Date(dateString);
	const isoString = date.toISOString();
	return isoString;
};

export const calculateExpiryWithDhms = (seconds) => {
	const currentDate = new Date();
	const expiryDate = new Date(currentDate.getTime() + seconds * 1000);

	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	const month = months[expiryDate.getMonth()];
	const day = expiryDate.getDate();
	const year = expiryDate.getFullYear();
	const hours = expiryDate.getHours();
	const minutes = expiryDate.getMinutes();
	const ampm = hours >= 12 ? 'PM' : 'AM';
	const formattedHours = hours % 12 || 12; // Convert 24-hour to 12-hour format

	const formattedExpiry = `${month} ${day}, ${year}, ${formattedHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;

	return formattedExpiry;
};

export const calculateExpiryWithDhmsUTC = (seconds) => {
	const currentDate = new Date();
	const expiryDate = new Date(currentDate.getTime() + seconds * 1000);

	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	const month = months[expiryDate.getUTCMonth()];
	const day = expiryDate.getUTCDate();
	const year = expiryDate.getUTCFullYear();
	const hours = expiryDate.getUTCHours();
	const minutes = expiryDate.getUTCMinutes();
	const ampm = hours >= 12 ? 'PM' : 'AM';
	const formattedHours = hours % 12 || 12; // Convert 24-hour to 12-hour format

	const formattedExpiry = `${month} ${day}, ${year}, ${formattedHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;

	return formattedExpiry;
};

export const formatDateFromSeconds = (seconds) => {
	const milliseconds = seconds * 1000; // Convert seconds to milliseconds
	const date = new Date(milliseconds); // Create a Date object using milliseconds

	// Extract year, month, and day from the date
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed, so add 1
	const day = String(date.getDate()).padStart(2, '0');

	// Format the date as 'YYYY-MM-DD'
	const formattedDate = `${year}-${month}-${day}`;

	return formattedDate;
};

export const SecondsToDDMMYY = (seconds) => {
	const date = new Date(seconds * 1000); // Convert seconds to milliseconds
	const day = date.getDate(); // Get the day of the month
	const month = date.getMonth() + 1; // Months are zero-indexed, so add 1
	const year = date.getFullYear() % 100; // Get the last two digits of the year

	// Return the formatted date as "dd/mm/yy"
	return `${day}/${month}/${year}`;
};

export const DateToMMDDYYYY = (date) => {
	return `${new Date(date).toLocaleDateString('en-US', { day: 'numeric', year: 'numeric', month: 'short' })}`;
};

export const DateToDDMMYYYY = (date) => {
	const options = { day: '2-digit', month: 'long', year: 'numeric' };
	return new Intl.DateTimeFormat('en-GB', options).format(new Date(date));
};

export const DateToWDDDMMYYYY = (date) => {
	return `${new Date(date).toLocaleDateString('en-US', { weekday: 'long', day: 'numeric', year: 'numeric', month: 'short' })}`;
};

export const LocaleDateToTime = (date) => {
	const getDate = new Date(date);
	return `${(getDate.getHours() > 9 ? getDate.getHours() : `0${getDate.getHours()}`) + ':' +
		(getDate.getMinutes() > 9 ? getDate.getMinutes() : `0${getDate.getMinutes()}`) + ':' +
		(getDate.getSeconds() > 9 ? getDate.getSeconds() : `0${getDate.getSeconds()}`)}`;
};

export const calculateSectionSizesWithExponent = (values, exponent = null) => {
	if (exponent === null) {
		const max = Math.max(...values);
		const nonZeroValues = values?.filter(value => value > 0);
		const min = nonZeroValues.length > 0 ? Math.min(...nonZeroValues) : 0;
		exponent = min > 0 && max > min ? Math.log(max) / Math.log(min) : 1;
	}
	const transformedValues = values.map(value => (value === 0 ? 0 : Math.pow(value, 1 / exponent)));
	const total = transformedValues.reduce((sum, value) => sum + value, 0);
	return transformedValues.map(value => (total === 0 ? 0 : (value / total) * 100));
};


export const getCodeBlockIcon = () => {
	return '<svg viewbox="0 -2 15 18">\n' + '\t<polyline class="ql-even ql-stroke" points="2.48 2.48 1 3.96 2.48 5.45"/>\n' + '\t<polyline class="ql-even ql-stroke" points="8.41 2.48 9.9 3.96 8.41 5.45"/>\n' + '\t<line class="ql-stroke" x1="6.19" y1="1" x2="4.71" y2="6.93"/>\n' + '\t<polyline class="ql-stroke" points="12.84 3 14 3 14 13 2 13 2 8.43"/>\n' + '</svg>';
};

export const langNameMapping = (langName) => {
	try {
		const langNamesMap = {
			'python': 'python',
			'javascript': 'javascript',
			'typescript': 'typescript',
			'java': 'java',
			'go': 'go',
			'c': 'c',
			'c++': 'cpp',
			'kotlin': 'kotlin',
			'c#': 'csharp',
			'swift': 'swift',
			'objective-c': 'objectiveC',
			'php': 'php',
			'ruby': 'ruby',
			'r': 'r'
		};
		return langNamesMap[langName];
	} catch {
		return '';
	}
};

export const deviceInfo = (user_agent) => {
	if (/Windows Phone/i.test(user_agent) || /WPDesktop/.test(user_agent)) {
		return 'Windows Phone';
	} else if (/iPad/.test(user_agent)) {
		return 'iPad';
	} else if (/iPod/.test(user_agent)) {
		return 'iPod Touch';
	} else if (/iPhone/.test(user_agent)) {
		return 'iPhone';
	} else if (/(BlackBerry|PlayBook|BB10)/i.test(user_agent)) {
		return 'BlackBerry';
	} else if (/Android/.test(user_agent) && !/Mobile/.test(user_agent)) {
		return 'Android Tablet';
	} else if (/Android/.test(user_agent)) {
		return 'Android';
	} else {
		return '';
	}
};

export const deviceType = (user_agent) => {
	const device = deviceInfo(user_agent);
	if (device === 'iPad' || device === 'Android Tablet') {
		return 'Tablet';
	} else if (device) {
		return 'Mobile';
	} else {
		return 'Desktop';
	}
};

export const OSInfo = (user_agent) => {
	if (/Windows/i.test(user_agent)) {
		if (/Phone/.test(user_agent) || /WPDesktop/.test(user_agent)) {
			return { os_name: 'Windows Phone', os_version: '' };
		}
		const match = /Windows NT ([0-9.]+)/i.exec(user_agent);
		if (match && match[1]) {
			const version = match[1];
			return { os_name: 'Windows', os_version: version };
		}
		return { os_name: 'Windows', os_version: '' };
	} else if (/(iPhone|iPad|iPod)/.test(user_agent)) {
		const match = /OS (\d+)_(\d+)_?(\d+)?/i.exec(user_agent);
		if (match && match[1]) {
			const versionParts = [match[1], match[2], match[3] || '0'];
			return { os_name: 'iOS', os_version: versionParts.join('.') };
		}
		return { os_name: 'iOS', os_version: '' };
	} else if (/Android/.test(user_agent)) {
		const match = /Android (\d+)\.(\d+)\.?(\d+)?/i.exec(user_agent);
		if (match && match[1]) {
			const versionParts = [match[1], match[2], match[3] || '0'];
			return { os_name: 'Android', os_version: versionParts.join('.') };
		}
		return { os_name: 'Android', os_version: '' };
	} else if (/(BlackBerry|PlayBook|BB10)/i.test(user_agent)) {
		return { os_name: 'BlackBerry', os_version: '' };
	} else if (/Mac/i.test(user_agent)) {
		const match = /Mac OS X (\d+)[_.](\d+)[_.]?(\d+)?/i.exec(user_agent);
		if (match && match[1]) {
			const versionParts = [match[1], match[2], match[3] || '0'];
			return { os_name: 'Mac OS X', os_version: versionParts.join('.') };
		}
		return { os_name: 'Mac OS X', os_version: '' };
	} else if (/Linux/.test(user_agent)) {
		return { os_name: 'Linux', os_version: '' };
	} else if (/CrOS/.test(user_agent)) {
		return { os_name: 'Chrome OS', os_version: '' };
	} else {
		return { os_name: '', os_version: '' };
	}
};

export const browserInfo = (user_agent, vendor) => {
	vendor = vendor || ''; // vendor is undefined for at least IE9
	if (user_agent.includes('OPR/')) {
		if (user_agent.includes('Mini')) {
			return 'Opera Mini';
		}
		return 'Opera';
	} else if (/(BlackBerry|PlayBook|BB10)/i.test(user_agent)) {
		return 'BlackBerry';
	} else if (user_agent.includes('IEMobile') || user_agent.includes('WPDesktop')) {
		return 'Internet Explorer Mobile';
	} else if (user_agent.includes('SamsungBrowser/')) {
		// https://developer.samsung.com/internet/user-agent-string-format
		return 'Samsung Internet';
	} else if (user_agent.includes('Edge') || user_agent.includes('Edg/')) {
		return 'Microsoft Edge';
	} else if (user_agent.includes('FBIOS')) {
		return 'Facebook Mobile';
	} else if (user_agent.includes('Chrome')) {
		return 'Chrome';
	} else if (user_agent.includes('CriOS')) {
		return 'Chrome iOS';
	} else if (user_agent.includes('UCWEB') || user_agent.includes('UCBrowser')) {
		return 'UC Browser';
	} else if (user_agent.includes('FxiOS')) {
		return 'Firefox iOS';
	} else if (vendor.includes('Apple')) {
		if (user_agent.includes('Mobile')) {
			return 'Mobile Safari';
		}
		return 'Safari';
	} else if (user_agent.includes('Android')) {
		return 'Android Mobile';
	} else if (user_agent.includes('Konqueror')) {
		return 'Konqueror';
	} else if (user_agent.includes('Firefox')) {
		return 'Firefox';
	} else if (user_agent.includes('MSIE') || user_agent.includes('Trident/')) {
		return 'Internet Explorer';
	} else if (user_agent.includes('Gecko')) {
		return 'Mozilla';
	} else {
		return '';
	}
};

export const getGoogleOauthOpts = function () {
	const options = {
		redirect_uri: `${window._env_.REACT_APP_GOOGLE_OAUTH_REDIRECT_URL}`,
		client_id: `${window._env_.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}`,
		access_type: 'offline',
		response_type: 'code',
		prompt: 'consent',
		scope: [
			'https://www.googleapis.com/auth/userinfo.profile',
			'https://www.googleapis.com/auth/userinfo.email'
		].join(' ')
	};

	const rootUrl = 'https://accounts.google.com/o/oauth2/v2/auth';

	return { options, rootUrl };
};

export const uuid = () => {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
		(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
	);
};

export const addVideoStreamToVideoTag = async (videoElementId) => {
	// console.log('addVideoStreamToVideoTag');
	const videoElement = document.getElementById(videoElementId);
	if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia && videoElement) {
		// Access the camera
		navigator.mediaDevices.getUserMedia({ video: true })
			.then(function (stream) {
				// Display the camera stream on the video element
				videoElement.srcObject = stream;
				sessionStorage.setItem('cameraStreamID', stream.id);
			})
			.catch(function (error) {
				console.error('Error accessing the camera: ', error);
			});
	}
};

export const stringToColour = (str) => {
	let hash = 0;
	str.split('').forEach(char => {
		hash = char.charCodeAt(0) + ((hash << 5) - hash);
	});
	let colour = '#';
	for (let i = 0; i < 3; i++) {
		const value = (hash >> (i * 8)) & 0xff;
		colour += value.toString(16).padStart(2, '0');
	}
	return colour;
};
export const checkCurrentUrl = () => {
	const currentPath = window.location.pathname;
	const currentQuery = new URLSearchParams(window.location.search);

	const pathPatterns = ProperPathName.map(path => {
		const [patternPath, queryString] = path.split('?');
		const escapedPath = patternPath
			.replace(/([.*+?^${}()|[\]\\])/g, '\\$1')
			.replace(/:(\w+)/g, '([^/]+)');
		const regex = new RegExp(`^${escapedPath}$`);

		if (!regex.test(currentPath)) return false;

		if (queryString) {
			const queryParams = new URLSearchParams(queryString);
			return [...queryParams].every(([key, value]) => currentQuery.get(key) === value);
		}

		return true;
	});

	return pathPatterns.some(pattern => pattern);
};




export default {
	countryMapper,
	setCookie,
	getCookie,
	deleteCookie,
	isEmailValid,
	isEmailPublic,
	isPasswordValid,
	toTitleCase,
	OSInfo,
	browserInfo,
	deviceInfo,
	deviceType,
	calculateExpiry,
	utcToDate,
	formatDateFromSeconds,
	timeDifference
};
