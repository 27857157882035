import { createSlice } from '@reduxjs/toolkit';
import { AppState } from '../../utils/Constants';
import {
	getSubscriptionProducts,
	getSubscriptions,
	createSubscription,
	updateSubscription,
	cancelSubscriptions,
	getPaymentMethods,
	createPaymentMethod,
	updateDefaultPaymentMethod,
	deletePaymentMethod,
	getInvoices,
	getUpComingInvoice,
	retryInvoices,
	getRollovers,
} from '../thunks/Subscription';

const initialState = {
	subscription_state: AppState.LOADING,
	is_loading: false,
	processing: false,
	subscription: {},
	stripe_products: [
		{
			stripe_pricings: [{}],
		},
	],
	user_subscription: [],
	payment_methods: null,
	invoices: null,
	upcoming_invoice: null,
	rollovers: null,
	error_message: null,
	success_message: null,
};

const SubscriptionSlice = createSlice({
	name: 'Subscription Slice',
	initialState: initialState,

	reducers: {},

	extraReducers: {
		[getSubscriptionProducts.pending]: (state) => {
			return {
				...state,
				processing: true,
			};
		},
		[getSubscriptionProducts.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing: false,
				stripe_products: payload,
			};
		},
		[getSubscriptionProducts.rejected]: (state) => {
			return {
				...state,
				processing: false,
			};
		},

		[getSubscriptions.pending]: (state) => {
			return {
				...state,
				processing: true,
			};
		},
		[getSubscriptions.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing: false,
				user_subscription: payload,
			};
		},
		[getSubscriptions.rejected]: (state) => {
			return {
				...state,
				processing: false,
			};
		},

		[createSubscription.pending]: (state) => {
			state.is_loading = true;
		},
		[createSubscription.fulfilled]: (state) => {
			state.is_loading = false;
		},
		[createSubscription.rejected]: (state) => {
			state.is_loading = false;
		},

		[updateSubscription.pending]: (state) => {
			state.processing = true;
		},
		[updateSubscription.fulfilled]: (state) => {
			state.processing = false;
		},
		[updateSubscription.rejected]: (state) => {
			state.processing = false;
		},

		[cancelSubscriptions.pending]: (state) => {
			state.processing = true;
		},
		[cancelSubscriptions.fulfilled]: (state) => {
			state.processing = false;
		},
		[cancelSubscriptions.rejected]: (state) => {
			state.processing = false;
		},

		[getPaymentMethods.pending]: (state) => {
			return {
				...state,
				processing: true,
			};
		},
		[getPaymentMethods.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing: false,
				payment_methods: payload,
			};
		},
		[getPaymentMethods.rejected]: (state) => {
			return {
				...state,
				processing: false,
			};
		},

		[createPaymentMethod.pending]: (state) => {
			state.processing = true;
		},
		[createPaymentMethod.fulfilled]: (state) => {
			state.processing = false;
		},
		[createPaymentMethod.rejected]: (state) => {
			state.processing = false;
		},

		[updateDefaultPaymentMethod.pending]: (state) => {
			state.processing = true;
		},
		[updateDefaultPaymentMethod.fulfilled]: (state) => {
			state.processing = false;
		},
		[updateDefaultPaymentMethod.rejected]: (state) => {
			state.processing = false;
		},

		[deletePaymentMethod.pending]: (state) => {
			state.is_loading = true;
		},
		[deletePaymentMethod.fulfilled]: (state) => {
			state.is_loading = false;
		},
		[deletePaymentMethod.rejected]: (state) => {
			state.is_loading = false;
		},

		[getInvoices.pending]: (state) => {
			return {
				...state,
				processing: true,
			};
		},
		[getInvoices.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing: false,
				invoices: payload,
			};
		},
		[getInvoices.rejected]: (state) => {
			return {
				...state,
				processing: false,
			};
		},

		[getUpComingInvoice.pending]: (state) => {
			return {
				...state,
				processing: true,
			};
		},
		[getUpComingInvoice.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing: false,
				upcoming_invoice: payload,
			};
		},
		[getUpComingInvoice.rejected]: (state) => {
			return {
				...state,
				processing: false,
			};
		},

		[retryInvoices.pending]: (state) => {
			return {
				...state,
				processing: true,
			};
		},
		[retryInvoices.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing: false,
				subscription: payload,
			};
		},
		[retryInvoices.rejected]: (state) => {
			return {
				...state,
				processing: false,
			};
		},

		[getRollovers.pending]: (state) => {
			return {
				...state,
				processing: true,
			};
		},
		[getRollovers.fulfilled]: (state, { payload }) => {
			return {
				...state,
				processing: false,
				rollovers: payload,
			};
		},
		[getRollovers.rejected]: (state) => {
			return {
				...state,
				processing: false,
			};
		},
	},
});

// export const { setAuth } = AuthSlice.actions;
export default SubscriptionSlice.reducer;
