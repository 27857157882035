import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useMediaQuery } from '@mui/material';

const CustomTooltip = ({ varrient, text, position, children, width, customPosition, disabled, customStyle, expiry }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState(false);
	const isMobile = useMediaQuery('(max-width: 767px)');


	const handleOpenTooltip = (event) => {
		if (disabled) {
			return;
		}
		setAnchorEl(event.currentTarget);
		setOpen(true);
	};

	const handleCloseTooltip = () => {
		if (disabled) {
			return;
		}
		setAnchorEl(null);
		setOpen(false);
	};

	const style = {
		backgroundColor: text ? '#121216' : '',
		padding: '4px 24px',
		borderRadius: '24px',
		maxWidth: isMobile ? '250px' : width || '310px',
		position: 'relative',
		color: 'white',
		...(customPosition ? customPosition : {})
	};

	if (varrient === 'result-sidebar-tooltip') {
		return (
			<span style={{ width: width || 'auto' }} onMouseEnter={handleOpenTooltip} onMouseLeave={handleCloseTooltip}>
				{children}
				<Popper
					open={open}
					anchorEl={anchorEl}
				// placement={position || 'bottom-start'}
				>
					{({ TransitionProps }) => (
						<div
							{...TransitionProps}
							style={{
								transformOrigin: getTransformOrigin(position),
								...style
							}}
						>
							<span className='body-2' style={{ color: 'white' }}>{text}</span>
						</div>
					)}
				</Popper>
			</span>
		);
	} else if (varrient === 'tooltip-with-expiry') {
		return (
			<span style={{ ...customStyle, width: width || 'auto' }} onMouseEnter={handleOpenTooltip} onMouseLeave={handleCloseTooltip}>
				{children}
				<Popper
					open={open}
					anchorEl={anchorEl}
					placement={position || 'bottom-start'}
				>
					{({ TransitionProps }) => (
						<div
							{...TransitionProps}
							style={{
								transformOrigin: getTransformOrigin(position),
								...style
							}}
						>
							<span className='body-2' style={{ color: 'white' }}>{text} {expiry && <span style={{marginLeft: '64px'}}>{expiry}</span>}</span>
						</div>
					)}
				</Popper>
			</span>
		);
	} else {
		return (
			<span style={{ ...customStyle, width: width || 'auto' }} onMouseEnter={handleOpenTooltip} onMouseLeave={handleCloseTooltip}>
				{children}
				<Popper
					open={open}
					anchorEl={anchorEl}
					placement={position || 'bottom-start'}
				>
					{({ TransitionProps }) => (
						<div
							{...TransitionProps}
							style={{
								transformOrigin: getTransformOrigin(position),
								...style
							}}
						>
							<span className='body-2' style={{ color: 'white' }}>{text}</span>
						</div>
					)}
				</Popper>
			</span>
		);
	}
};

// Utility function to determine transform origin based on placement
const getTransformOrigin = (position) => {
	if (!position) {
		position = 'bottom-start';
	}
	const [vertical, horizontal] = position.split('-');
	const axis = vertical === 'bottom' ? 'top' : 'bottom';
	return `${axis} ${horizontal}`;
};

CustomTooltip.propTypes = {
	text: PropTypes.string.isRequired,
	position: PropTypes.oneOf([
		'top-start', 'top', 'top-end',
		'left-start', 'left', 'left-end',
		'right-start', 'right', 'right-end',
		'bottom-start', 'bottom', 'bottom-end',
	]).isRequired,
	children: PropTypes.node.isRequired,
};

export default CustomTooltip;
