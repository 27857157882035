import { createAsyncThunk } from '@reduxjs/toolkit';
import { instance, secure_instance } from '../../api/axios/axios-config';
import { handleApiError } from '../../api/axios/interceptors';

export const getSubscriptionProducts = createAsyncThunk(
	'subscription/getSubscriptionProducts',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const request = await instance.request({
				url: '/v1/stripe_products/',
				method: 'GET',
			});

			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const getSubscriptions = createAsyncThunk(
	'subscription/getSubscription',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/subscriptions/',
				method: 'GET',
				params: data
			});

			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const createSubscription = createAsyncThunk(
	'subscription/createSubscription',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/subscriptions/',
				method: 'Post',
				data: { stripe_product_id: data.stripe_product_id, interval: data.interval },
			});

			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const updateSubscription = createAsyncThunk(
	'subscription/updateSubscription',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/subscriptions/${data.sub_id}`,
				method: 'PATCH',
				data: {
					stripe_product_id: data.stripe_pricing_id,
					interval: data.interval
				},
			});
			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const cancelSubscriptions = createAsyncThunk(
	'subscription/cancelSubscriptions',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/subscriptions/${data.sub_id}`,
				method: 'DELETE',
			});

			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const getPaymentMethods = createAsyncThunk(
	'subscription/getPaymentMethods',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/stripe_customers/list_payment_methods/',
				method: 'GET',
				params: data
			});

			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const createPaymentMethod = createAsyncThunk(
	'subscription/createPaymentMethod',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const { paymentMethod } = await data.stripe.createPaymentMethod({
				type: 'card',
				card: data.elements.getElement(data.CardNumberElement),
				billing_details: {
					name: data.name,
				},
			});

			const request = await secure_instance.request({
				url: '/v1/stripe_customers/attach_payment_method/',
				method: 'Post',
				data: {
					payment_method_id: paymentMethod.id,
					make_default_payment_method: data.isDefault
				},
			});
			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const updateDefaultPaymentMethod = createAsyncThunk(
	'subscription/updateDefaultPaymentMethod',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/stripe_customers/default_payment_method/`,
				method: 'PATCH',
				data: {
					payment_method_id: data.payment_method_id,
				},
			});
			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const deletePaymentMethod = createAsyncThunk(
	'subscription/deletePaymentMethod',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/stripe_customers/detach_payment_method/',
				method: 'Post',
				data: {
					payment_method_id: data.id,
				},
			});
			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const getInvoices = createAsyncThunk(
	'subscription/getInvoices',
	async (_, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/stripe_customers/customer_invoices/',
				method: 'GET',
			});

			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const getUpComingInvoice = createAsyncThunk(
	'subscription/getUpComingInvoice',
	async (_, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/stripe_customers/upcoming_invoice/',
				method: 'GET',
			});

			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const retryInvoices = createAsyncThunk(
	'subscription/retryInvoices',
	async (_, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/stripe_customers/retry_invoice/',
				method: 'GET',
			});

			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);

export const getRollovers = createAsyncThunk(
	'subscription/getRollovers',
	async (_, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/rollovers/',
				method: 'GET',
			});

			return request.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	}
);
