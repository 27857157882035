import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfile, getTanentProfile } from '../../redux/thunks/UserProfile';
import CryptoJS from 'crypto-js';
import hexDigest from 'crypto-js/enc-hex';
import { GenerateHash } from '../../redux/thunks/CustomerSupport';

export const initializeChatwoot = (token, baseUrl) => {
	if (window.chatwootSDK) {
		console.log(`Initializing Chatwoot with token: ${token}`);
		window.chatwootSDK.run({
			websiteToken: token,
			baseUrl: baseUrl,
		});
	}
};

export const setChatwootUser = async (auth, userProfileData, dispatch, name) => {
	console.log(auth, userProfileData, dispatch, name, 'Chatwoot SDK');
	if (auth?.data?.access_token && userProfileData ) {	
		const result =  await dispatch(GenerateHash({ is_candidate: auth.data?.role?.id === 'candidate' ? true : false }));
		const hash = result.payload[1].hash;
					
		if (hash)
		{
			window.$chatwoot.setUser(auth?.data?.user_id.toString(), {
				email: auth?.data?.email,
				name: name,
				identifier_hash: hash,
			});
		}
	}
};

export const resetChatwootUser = () => {
	if (window.$chatwoot) {
		console.log('Resetting Chatwoot user');
		window.$chatwoot.reset();
	}
};

export default function ChatwootComponent({ children }) {

	const baseUrl = window._env_.CHATWOOT_BASE_URL;
	const adminToken = window._env_.CHATWOOT_WEB_ACCESS_TOKEN;
	const candidateToken = window._env_.CANDIDATE_CHATWOOT_WEB_ACCESS_TOKEN;
	const { userProfileData } = useSelector((state) => state.userProfileReducer);
	const auth = useSelector((state) => state.authReducer);
	const dispatch = useDispatch();
	const USER_NAME = `${userProfileData?.first_name} ${userProfileData?.last_name}`.trim();

	const loadChatwootScript = () => {
		if (!document.getElementById('chatwoot-script')) {
			const script = document.createElement('script');
			script.id = 'chatwoot-script';
			script.src = `${baseUrl}/packs/js/sdk.js`;
			script.async = true;
			script.onload = () => {
				console.log('Chatwoot SDK script loaded');
			};
			document.body.appendChild(script);
		}
	};

	useEffect(() => {
		if (auth.data?.access_token) {
			dispatch(getUserProfile({}));
			if (auth.data?.role?.id !== 'candidate') {
				dispatch(getTanentProfile({ id: '', isCallForCurrAssessment: false }));
			}
		}
	}, [auth.data?.access_token]);

	useEffect(() => {
		loadChatwootScript();

		const initializeWidget = () => {
			if (!auth.data?.access_token) {
				// Unauthenticated user
				initializeChatwoot(adminToken, baseUrl);
			} else if (auth?.data?.role?.id === 'candidate') {
				// Candidate role
				initializeChatwoot(candidateToken, baseUrl);
				setChatwootUser(auth, userProfileData, dispatch, USER_NAME);
			} else {
				// Admin role
				initializeChatwoot(adminToken, baseUrl);
				setChatwootUser(auth, userProfileData, dispatch, USER_NAME);
			}
		};

		const checkChatwootReady = setInterval(() => {
			if (window.chatwootSDK) {
				clearInterval(checkChatwootReady);
				initializeWidget();
			}
		}, 500);

		return () => {
			clearInterval(checkChatwootReady);
			// resetChatwootUser();
		};
	}, [auth.data?.access_token, auth?.data?.role?.id, USER_NAME]);

	return (
		<div>
			{children}
		</div>
	);
}