import React, { useState } from 'react';
import CustomTooltip from './CustomTooltip';
import { SecondsToDDMMYY } from '../../utils/utilities';

export default function ProgressBar(props) {
	const { value, size, varrient, style, isHideValue, text, overage, usageFreeAssessments, totalFreeAssessments, tenantStatus, total, segments } = props;
	const [progressBarStyle, setProgressBarStyle] = useState({});

	setTimeout(() => {
		setProgressBarStyle({ width: `${value}%` });
	}, 50);

	if (varrient === 'custom') {
		return (
			<div className='w-100 d-flex flex-column' style={{ ...style }}>
				<div className='w-100 d-flex flex-column align-items-start' style={{ ...props.innerStyle, gap: '16px' }}>
					<span className={props?.title?.className ? props?.title?.className : 'subtitle-2 dark-100'} style={{ ...props?.title?.style }}>{props?.title?.text || 'Assessment Progress'}</span>
					{!isHideValue &&
						<>
							{(typeof value === 'number') &&
								<span className={props?.valueText?.className ? props?.valueText?.className : 'body-2 dark-100'} style={{ ...props?.valueText?.style }}>{`${value}% Complete`}</span>
							}
						</>
					}
				</div>
				<div className='progress-bar-container w-100 position-relative' style={{ ...props?.progressBar?.style, gap: '4px' }}>
					<div className='h-100 w-100' style={{ opacity: '0.2', position: 'absolute' }}></div>
					<div style={{ width: `${value}%`, ...props?.progressBar?.innerStyle }} className='progress-bar'></div>
				</div>
			</div>
		);
	} else if (varrient === 'progress-with-segment') {
		return (
			<div className='segment-progress-main-container'>
				<div className='segment-progress-container'>
					<span className='body-2 dark-50'>{text}</span>
					{tenantStatus === 'trialing' ?
						<div className='progress-bar-container w-100 position-relative'>
							<CustomTooltip text={`Free Assessment: ${usageFreeAssessments}/${totalFreeAssessments}`} position='bottom-end'>
								<div className='h-100 w-100' style={{ opacity: '0.2', position: 'absolute' }}></div>
								<div style={{ width: `${((usageFreeAssessments / totalFreeAssessments) * 100)}%`}} className='progress-bar'></div>
							</CustomTooltip>
						</div>
						:
						<div className='segment-progress-bar-wrapper'>
							<div className='segment-progress-bar-left' style={{ width: `calc(${total}% - 10%)` }}>
								{segments?.map((segment, index) => {
									const usageWidth = (segment?.rollover_usage / segment?.rollover_count) * 100;
									return (
										<div
											key={index}
											className='progress-segment'
											style={{ width: `${segment?.width}%`, borderRight: '2px solid #FFF' }}
										>
											{segment?.label ?
												<CustomTooltip text={`${segment?.label}: ${segment?.rollover_usage}/${segment?.rollover_count}`} position='bottom-end'>
													<div className='h-100 w-100' style={{ backgroundColor: segment?.color, opacity: '0.2', position: 'absolute' }}></div>
													<div className={`progress-bar-use ${(index === 0 && usageWidth > 0) && 'active-left'}`} style={{ width: `${usageWidth}%`, height: '100%', backgroundColor: segment?.color }} />
												</CustomTooltip>
												:
												<CustomTooltip varrient='tooltip-with-expiry' text={`Rollover: ${segment?.rollover_usage}/${segment?.rollover_count}`} expiry={`Expiry: ${SecondsToDDMMYY(segment?.valid_until)}`} position='bottom-end'>
													<div className='h-100 w-100' style={{ backgroundColor: segment?.color ? segment?.color : '#FFB300', opacity: '0.2', position: 'absolute' }}></div>
													<div className={`progress-bar-use ${(index === 0 && usageWidth > 0) && 'active-left'}`} style={{ width: `${usageWidth}%`, height: '100%', backgroundColor: segment?.color ? segment?.color : '#FFB300' }} />
												</CustomTooltip>
											}
										</div>
									);
								})}
							</div>
							<div className='segment-progress-bar-right' style={{ width: `10%` }}>
								<div className='progress-segment' style={{ width: '100%' }}>
									<CustomTooltip text={`Overage: ${overage}/∞`} position='bottom-end'>
										<div className='h-100 w-100' style={{ backgroundColor: '#F44336', opacity: '0.2', position: 'absolute' }}></div>
										<div className={`progress-bar-use ${overage > 0 && 'active-right'}`} style={{ width: (overage > 0) ? '100%' : `0%`, height: '100%', backgroundColor: '#F44336' }} />
									</CustomTooltip>
								</div>
							</div>
						</div>
					}
				</div>
				<div className='legend'>
					{tenantStatus === 'trialing' ?
						<div className='legend-item'>
							<span className='legend-color' style={{ backgroundColor: '#896BFF' }}></span>
							<span className='dark-50' style={{fontSize: '11px'}}>{'Free Assessments'}</span>
						</div>
						:
						<>
							{segments?.some(s => s.label !== 'Plan') && 
								<div className='legend-item'>
									<span className='legend-color' style={{ backgroundColor: '#FFB300' }}></span>
									<span className='dark-50' style={{fontSize: '11px'}}>{'Rollover Assessments'}</span>
								</div>
							}
							{segments?.some(s => s.label === 'Plan') && 
								<div className='legend-item'>
									<span className='legend-color' style={{ backgroundColor: '#4CAF50' }}></span>
									<span className='dark-50' style={{fontSize: '11px'}}>{'Plan Assessments'}</span>
								</div>
							}
							<div className='legend-item'>
								<span className='legend-color' style={{ backgroundColor: '#F44336' }}></span>
								<span className='dark-50' style={{fontSize: '11px'}}>{'Overage Assessments'}</span>
							</div>
						</>
					}
				</div>
			</div>
		);
	} else if (varrient === 'progress-with-upload-doc') {
		return (
			<div className='w-100 d-flex flex-row align-items-center' style={{ ...style, gap: '12px' }}>
				<div className='progress-bar-container w-100 position-relative' style={{ ...props?.progressBar?.style }}>
					<div className='h-100 w-100' style={{ opacity: '0.2', position: 'absolute' }}></div>
					<div style={{ width: `${value}%`, ...props?.progressBar?.innerStyle }} className='progress-bar'></div>
				</div>
				<span className={props?.valueText?.className ? props?.valueText?.className : 'body-2 dark-50'}>{`${value}%`}</span>
			</div>
		);
	} else if (varrient === 'progress-with-white-bg') {
		return (
			<div className='d-flex flex-column align-items-start'>
				<span className='body-2' >{`${value}%`}</span>
				<div className='progress-bar-container' style={{background: '#FFF'}}>
					<div style={progressBarStyle} className='progress-bar'></div>
				</div>
			</div>
		);
	} else if (varrient === 'progress-with-presentage') {
		return (
			<div className='w-100 d-flex flex-row align-items-center' style={{ ...style, gap: '12px', padding: '0px 12px' }}>
				<span className={props?.valueText?.className ? props?.valueText?.className : 'headline-small bold black-400'} style={{ color: value <= 50 ? '#FC4848' : value <= 75 ? '#F4C20D' : '#00D69A' }}>{`${value}%`}</span>
				<div className='progress-bar-container w-100 position-relative' style={{ ...props?.progressBar?.style }}>
					<div className='h-100 w-100' style={{ backgroundColor: value <= 50 ? '#FC4848' : value <= 75 ? '#F4C20D' : '#00D69A', opacity: '0.2', position: 'absolute' }}></div>
					<div style={{ backgroundColor: value <= 50 ? '#FC4848' : value <= 75 ? '#F4C20D' : '#00D69A', width: `${value}%`, ...props?.progressBar?.innerStyle }} className='progress-bar'></div>
				</div>
			</div>
		);
	} else if (varrient === 'progress-with-number-value') {
		return (
			<div className='w-100 d-flex flex-column align-items-start' style={{ ...style, gap: '4px' }}>
				<span className={props?.valueText?.className ? props?.valueText?.className : 'body-2 dark-100'}>{`${value}`}/10</span>
				<div className='progress-bar-container-number-value w-100 position-relative' style={{ ...props?.progressBar?.style }}>
					<div className='w-100' style={{ position: 'absolute' }}></div>
					<div style={{ width: `${(value * 10)}%`, ...props?.progressBar?.innerStyle }} className='progress-bar'></div>
				</div>
			</div>
		);
	} else {
		return (
			size === 'lg'
				?
				<div className='progress-bar-container-lg'>
					<CustomTooltip text={text} customPosition={{bottom: '10px'}} position='top-end'>
						<div style={progressBarStyle} className='progress-bar'></div>
					</CustomTooltip>
				</div>
				:
				<div className='d-flex flex-column align-items-start'>
					<span className='body-2' >{`${value}%`}</span>
					<div className='progress-bar-container'>
						<div style={progressBarStyle} className='progress-bar'></div>
					</div>
				</div>
		);
	}
}
