import { createAsyncThunk } from '@reduxjs/toolkit';
import { secure_instance } from '../../api/axios/axios-config';
import { handleApiError } from '../../api/axios/interceptors';

export const CreateIntercomTestPackTicket = createAsyncThunk(
	'testPack/CreateIntercomTestPackTicket',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `v1/chatwoot/tickets/test_packs`,
				method: 'POST',
				data: data,
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);

export const GenerateHash = createAsyncThunk(
	'testPack/GenerateHash',
	async ({ is_candidate }, { rejectWithValue, dispatch }) => {
		try {
			const request = await secure_instance.request({
				url: `v1/chatwoot/generate_hash?is_candidate=${is_candidate}`,
				method: 'GET',
			});

			return request?.data;
		} catch (error) {
			return handleApiError(error, rejectWithValue, dispatch);
		}
	},
);